<template>
  <div class="verification-email-wrapper">
    <h1>
      {{ $t('verificationEmail.pleaseVerifyYourEmail') }}
    </h1>
    <p class="description">
      {{ $t('verificationEmail.toKeepYourPersonalDataSafeAndSecureAnEmailHasBeenSentTo') }}
    </p>

    <p class="email font-outfit-semi-bold">
      {{ userEmail }}
    </p>

    <v-form @submit.native.prevent="onSubmit">
      <p>
        {{ $t('verificationEmail.pleaseEnterTheCodeYouReceivedBelow') }}
      </p>
      <v-text-field
        v-model="form.code"
        :maxlength="6"
        outlined
        background-color="white"
        :label="$t('verificationEmail.enterCode')"
        @input="form.code = form.code.toUpperCase()"
      />
      <v-btn
        block
        x-large
        color="primary"
        elevation="0"
        :disabled="loading || isBtnDisabled"
        :loading="loading"
        @click="onSubmit"
      >
        {{ $t('verificationEmail.verify') }}
      </v-btn>
    </v-form>

    <div class="d-flex justify-content-center didntReceiveIt">
      <p class="m-0 mr-1 ">
        {{ $t('verificationEmail.didntReceiveIt') }}
      </p>
      <p
        class="m-0 text-blue"
        :class="resendCodeClasses"
        @click="resend"
      >
        {{ $t('verificationEmail.resendCode') }}
      </p>
    </div>
    <p class="m-0">
      {{ $t('verificationEmail.alsoCheckYourJunkOrSpamFolder') }}
    </p>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _omit from 'lodash/omit'

export default {
  name: 'VerificationEmailForm',

  data () {
    return {
      loading: false,
      sendingCode: false,
      form: {
        code: null,
        email: null
      }
    }
  },

  computed: {
    ...mapGetters('user', ['userEmail', 'isVerified', 'ideasCount', 'isInvited']),
    ...mapGetters('ideaCollection', ['getSharedIdeasCount']),

    isBtnDisabled () {
      return !this.form.code
    },

    resendCodeClasses () {
      return {
        'text-silver': this.sendingCode,
        'cursor-pointer': !this.sendingCode,
        'cursor-not-allowed': this.sendingCode
      }
    }
  },

  mounted () {
    this.form.email = this.userEmail
  },

  methods: {
    ...mapActions('user', [
      'emailVerification',
      'resendEmailVerification'
    ]),

    onSubmit () {
      if (this.isBtnDisabled) return

      this.loading = true
      this.emailVerification(this.form)
        .then(_ => {
          if (this.ideasCount === 0 && this.getSharedIdeasCount === 0 && !this.isInvited) {
            this.$router.push({ name: 'select-idea', query: this.$router.currentRoute.query })

            return
          }

          this.$router.push({ name: 'home', query: this.$router.currentRoute.query })
        })
        .finally(() => {
          this.loading = false
        })
    },

    resend () {
      if (this.sendingCode) return

      this.sendingCode = true
      this.resendEmailVerification(_omit(this.form, ['code']))
        .then(_ => {
          this.$notify({
            title: this.$t('success'),
            message: this.$t('verificationEmail.newCodeSuccessfullySentCheckYourEmail'),
            type: 'success'
          })
        })
        .finally(() => {
          this.sendingCode = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.verification-email-wrapper {
  width: 100%;
  max-width: 550px;

  .description {
    max-width: 320px;
    margin: 0 auto;
  }

  .email {
    margin: 30px 0;
  }

  .didntReceiveIt {
    margin-top: 30px;
  }
}
</style>
